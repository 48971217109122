.App {
  text-align: center;
  margin: 2rem;
}

.section {
  text-align: left;
  max-width: 720px;
  margin: 4rem auto auto;
  z-index: 5;
  color: black
}

div {
  margin: 0;
  padding: 0;
}

body {
  overflow-x: hidden;
  overflow-y: scroll;
}

.active > div {
  background-color: #3aa859;
  color: white;
}

* {
  font-family: Poppins;
}